<template>
  <transition :appear="true" name="slide">
    <div @touchmove.prevent class="container">

      <scroll class="scroll" :data="lotteryList">
        <div>
          <div class="lottery-container" :style="lottery_container_st">
            <back class="back" back-url="/"></back>
            <div class="wrapper">
              <div
                class="lottery-item"
                :class="{normal: index !== 4, special: index === 4}"
                v-for="(item,index) in lotteryList" :key="index"
              >
                <img
                  class="pic"
                  :class="{'on-touch': onTouch && index === 4}"
                  :src="item.goods_img"
                  alt=""
                  @touchstart="touchStartLottery(index)"
                  @touchend="touchEndLottery(index)"
                  @click="goLottery(index)"
                />
                <div class="mask" v-show="item.index === nowIndex"></div>
              </div>
            </div>
          </div>
        </div>
      </scroll>
      <overlay :show="showWin">
        <div class="overlay-block" :style="overlay_block_st">
           <div class="titels">{{msgs}} </div>
          <img
            style="margin-top: 210px;"
            class="overlay-pic"
            :src="prizeUrl"
            alt=""
          />
          <div class="br"></div>
          <div class="close-overlay" @click="winCLose"></div>
        </div>
      </overlay>
      <overlay :show="showThanks">
        <div class="overlay-block">
          <div class="overlay-sorry" :style="overlay_sorry_st">
            <router-link tag="div" to="/home" class="home"></router-link>
          </div>
          <div class="close-overlay" @click="thanksClose"></div>
        </div>
      </overlay>
    </div>
  </transition>
</template>

<script>
import Back from '@/components/back/Back'
import Scroll from '@/components/scroll/scroll'
import { Overlay, Toast } from 'vant'
import { lottery21Day } from '@/api/SignIn'
import { mapGetters } from 'vuex'
import { getCurrentConnect } from '@/api/Activity'

export default {
  name: 'Lottery',
  components: {
    Back,
    Scroll,
    Overlay
  },
  data () {
    return {
      thanks: {
        index: 0,
        goods_id: 0,
        goods_img: 'https://msyd-1304515158.cos.ap-beijing.myqcloud.com/assets/lottery/thanks%402x.png'
      },
      btn: {
        index: 8,
        goods_id: -1,
        goods_img: 'https://msyd-1304515158.cos.ap-beijing.myqcloud.com/assets/lottery/go-lottery%402x.png'
      },
      lotteryList_old: [],
      lotteryList: [],
      lotteryList_new: [
      ],
      onTouch: false, // 是否触摸
      nowIndex: -1, // 当前转动位置
      speed: 200, // 初始移动速度
      times: 0, // 转动次数
      cycle: 50, // 转动基本次数
      prize: -1, // 中奖位置
      prizeUrl: '', // 中奖图片
      startLottery: false, // 点击抽奖按钮
      showWin: false, // 中奖标记
      showThanks: false, // 未中奖标记
      msgs: '恭喜您中奖啦！',
      activity: {},
      lottery_container_st: '',
      overlay_sorry_st: '',
      overlay_block_st: '',
      prizeItem: {}
    }
  },
  computed: {
    ...mapGetters([
      'userGuid',
      'agencyGuid'
    ])
  },
  created () {
    this._getCurrentConnect()
    // this.showjangp()
  },
  methods: {
    _getCurrentConnect () {
      let _this = this
      getCurrentConnect({ activity_type_new: 1, c_user_guid: this.userGuid, agency_guid: this.agencyGuid }).then(res => {
        _this.activity = res.data
        if (res.data.activitySetting) {
          if (res.data.activitySetting.lottery_bg_img) {
            _this.lottery_container_st = 'background-image:url(' + res.data.activitySetting.lottery_bg_img + ')'
          }
          if (res.data.activitySetting.lottery_fail_bg_img) {
            _this.overlay_sorry_st = 'background-image:url(' + res.data.activitySetting.lottery_fail_bg_img + ')'
          }
          if (res.data.activitySetting.lottery_success_bg_img) {
            _this.overlay_block_st = 'background-image:url(' + res.data.activitySetting.lottery_success_bg_img + ')'
          }
        }
        if (res.data.lotterygoods) {
          _this.lotteryList.push(...res.data.lotterygoods)
          var count = 8 - _this.lotteryList.length
          for (var i = 0; i < count; i++) {
            _this.lotteryList.push(_this.thanks)
          }
        }
        _this.lotteryList.sort(() => 0.5 - Math.random())
        _this.lotteryList.sort(() => 0.5 - Math.random())
        _this.lotteryList.splice(4, 0, _this.btn)
        let list = []
        _this.lotteryList.forEach((element, index) => {
          list.push({
            index: index,
            goods_id: element.goods_id,
            goods_img: element.goods_img,
            goods_name: element.goods_name
          })
        })

        _this.lotteryList = list
        console.log('_this.lotteryList---', _this.lotteryList)
      })
    },
    touchStartLottery (index) {
      if (index !== 4) {
        return // 点击的不是立即抽奖
      }
      if (this.startLottery) {
        return // 抽奖中
      }
      this.onTouch = true
    },
    touchEndLottery (index) {
      if (index !== 4) {
        return // 点击的不是立即抽奖
      }
      if (this.startLottery) {
        return // 抽奖中
      }
      this.onTouch = false
    },
    goLottery (index) {
      if (index !== 4) {
        return // 点击的不是立即抽奖
      }
      if (this.startLottery) {
        return // 抽奖中
      }
      this.startLottery = true
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: '加载中...'
      })
      lottery21Day({
        lottery_user: this.userGuid,
        lottery_agency: this.agencyGuid
      }).then((res) => {
        const { code, msg, data } = res
        if (code === 200) {
          this.prize = data.goods_id
          this.prizeItem = data
          Toast.clear()
          this.startRoll()
        } else {
          this.startLottery = false
          Toast.fail(msg)
        }
      })
    },
    startRoll () {
      this.times += 1
      this.oneRoll()
      if (this.times < this.cycle) {
        this.speed -= 10
      } else {
        this.speed += 20
      }
      if (this.speed < 40) {
        this.speed = 40
      }
      if (this.speed > 500) {
        let nowobj = this.lotteryList[this.nowIndex].goods_id
        if (this.prize === nowobj) {
          clearTimeout(this.timer)
          this.showjangp()
          return
        }
      }
      this.timer = setTimeout(this.startRoll, this.speed)
    },
    oneRoll () {
      if (this.nowIndex >= 8) {
        this.nowIndex = 0
      } else {
        this.nowIndex += 1
        if (this.nowIndex === 4) {
          this.nowIndex += 1
        }
      }
    },
    thanksClose () {
      this.showThanks = false
      this.$router.push('/personal/sign-in21day?activity_guid=' + this.activity.activity_guid)
    },
    winCLose () {
      this.showWin = false
      this.$router.push('/personal/sign-in21day?activity_guid=' + this.activity.activity_guid)
    },
    showjangp () {
      let _this = this
      console.log('this.prize--', this.prize)
      _this.lotteryList.forEach(item => {
        if (item.goods_id === this.prize) {
          this.prizeUrl = item.goods_img
        }
      })
      if (this.prize <= 0) {
        setTimeout(() => {
          this.showThanks = true
        }, 1000)
      } else {
        setTimeout(() => {
          this.showWin = true
        }, 1000)
      }
    }
  },
  mounted () {
    // this.prize = 1
    // this.showjangp()
  }

}
</script>

<style scoped lang="stylus">
@import "~@/assets/stylus/mixin"
.slide-enter-active, .slide-leave-active
  transition all .3s

.slide-enter, .slide-leave-to
  transform translate3d(100%, 0, 0)

.container
  position absolute
  z-index 10
  top 0
  left 0
  right 0
  bottom 0
  background rgba(245, 245, 244, 1)

  .scroll
    position absolute
    top 0
    left 0
    right 0
    bottom 0
    overflow hidden
    background-color rgba(213, 44, 44, 1)

    .lottery-container
      position:absolute;
      display flex
      justify-content center
      width 100%
      height 100%
      bg-image2('assets/lottery/lottery_202309_1')
      background-size 100% 100%
      background-repeat no-repeat

      .wrapper
        display flex
        align-items center
        flex-wrap wrap
        justify-content space-around
        width 570px
        height 598px
        padding-top 596px

        .lottery-item
          position relative
          display flex
          align-items center
          justify-content center
          width 172px
          border-radius 20px
          overflow hidden

          &.normal
            height 186px

          &.special
            height 172px

          .pic
            transition all 0.3s
            width 100%
            height 100%
            -webkit-touch-callout: none
            -webkit-user-select: none
            -moz-user-select: none
            -ms-user-select: none
            user-select: none

            &.on-touch
              width 90%
              height 90%

          .mask
            position absolute
            width 100%
            height 100%
            background rgba(237, 164, 83, .6)

  .overlay-block
    position: relative;
    display flex
    flex-wrap wrap
    justify-content center
    align-items center
    width 100%
    margin 100px auto 0 auto
    height 808px
    bg-image2('assets/lottery/lottery_cg202309')
    background-size 100% 100%
    background-repeat no-repeat
    .overlay-pic
      width 300px
      margin-top 120px;
    .titels{
      font-size: 44px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #FFF2C7;
      position: absolute;
      top: 250px;
    }
    .br
      width 100%
    img{
      height: 290px;
    }
  .overlay-sorry
    display flex
    justify-content center
    width 100%
    height 808px
    bg-image2('assets/lottery/sorry')
    background-size 100% 100%
    background-repeat no-repeat

    .home
      width 280px
      height 80px
      margin-top 640px
      bg-image2('assets/lottery/go-home')
      background-size 100% 100%
      background-repeat no-repeat

  .close-overlay
    width 54px
    height 54px
    margin-top 50px
    bg-image2('assets/lottery/x')
    background-size 100% 100%
    background-repeat no-repeat
.back
  position absolute
  top 0
  background none
</style>
